import React from "react";
import imagedtp from "../../assets/images/image.png";
import { StaticQuery, graphql } from "gatsby";

const Banner = props => (


  <StaticQuery
  query={graphql`
  query BannerwwdComponentQuery {
  
  
    allContentfulWhatWeDoBanner {
      edges {
        node {
          title,
          subtitle {
            childMarkdownRemark {
                        excerpt(pruneLength: 10000,format: HTML)
                     }
          },
          titleDescription,
          description{
           childMarkdownRemark {
                        excerpt(pruneLength: 10000,format: HTML)
                     }
          }
        }
      }
    }
        
}
`}

render={data => (

  <section id="banner_what_we_do" className="major">
    <div className="inner">
      <header className="major">
        <h1>{data.allContentfulWhatWeDoBanner.edges[0].node.title}</h1>
        <hr />
      </header>
      <div className="content">
      <div
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulWhatWeDoBanner.edges[0].node.subtitle
                    .childMarkdownRemark.excerpt
              }}
            />
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2> {data.allContentfulWhatWeDoBanner.edges[0].node.titleDescription} </h2>
          <div
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulWhatWeDoBanner.edges[0].node.description
                    .childMarkdownRemark.excerpt
              }}
            />


          
        </div>
      </div>
      <div className="blocs_banner_wwd">
      
        <div className="Vertical_text">
    
       <p className="subtitle fancy"><span> E2E Digitalzation Design and Delivery Company</span></p>
      
        </div>
       

 <div id="container">

<ol class="organizational-chart">
    <li>
        <div>
        <h3> Unleashing Digital Power</h3>
            <ul>
              <li>Full Business and Operational Enablement Solutions</li>
              <li>
                Deep knowledge on industry business and operation processes
              </li>
              <li>Cloud native architectures</li>
            </ul>
        </div>

        <div>
           
            <img src={imagedtp} alt="" />
        </div>


        <div className="">
        <h3> Make It Easy Doing Business Anywhere</h3>
            <ul>
              <li> Full Web-Scale technologies</li>
              <li>Massive scalability</li>
              <li>Global footprint and ambition</li>
              <li>Rich ecosystem </li>
              <li>IoT and 5G minded</li>
            </ul>
        </div>

        <ol>
            <li>
                <div>
                    <p>Digital Transformation Fast Track</p>
                </div>
             
            </li>
            <li>
                <div>
                <p>ET Brain for Improved Experience with AI</p>
                </div>
         
            </li>
            <li>
                <div>
                <p>IoT E2E Digital Business Enabler</p>
                </div>
    
            </li>
            <li>
                <div>
                <p>Agile Cloud PaaS Delivery</p>
                </div>
      
            </li>
        </ol>
    </li>
</ol>

</div>
        
     

      </div>

    </div>
  </section>
   )}
   />
);

export default Banner;
