import React from "react";


class Panel extends React.Component {

    
    render(){
        console.log()
        return <div className={'tab_domains'+ this.props.index}>{this.props.children}</div>
      }



}

export default Panel;