import React from "react"
import Layout from "./../components/beta/layout"

export default () => (

   
//    <div className="row">
// hello from the other side
//    </div>


<Layout>
  
</Layout>
)