import React from "react";
import chat from "../../assets/images/icn_chat.png";
import job from "../../assets/images/icn_job.png";
import Alltabs from "../../components/beta/tabsdomains/alltabs";

import { StaticQuery, graphql } from "gatsby";



const Banner = props => (

  <StaticQuery
query={graphql`
query BannerourdComponentQuery {


  allContentfulImprint {
    edges {
      node {
        title,
        description {
           childMarkdownRemark {
                       html
                    }
        }      
      }
    }
  }


  allContentfulGetInTouch  {
    edges {
      node 
      {
        title ,
                getintouchItems {
          title,
                  description {
                      childMarkdownRemark {
                          html
                      }
                  },
                  linkButton {
                      childMarkdownRemark {
                          html
                      }
                  },
                  icon{
                    file {
                        url
                        }
            },
  
        }
      }
    }
  }
      
}
`}

render={data => (
  <section id="banner_our_domains" className="major">
    <div className="inner">
      <div className="blocs_banner_our_domains" id="blocs_banner_our_domains">
        <header className="major">
          <h1 className="ourdomains_firsttitle">OUr Domains</h1>
          <hr />
        </header>

        <div id="Tabs_bloc_domains">
          <Alltabs />
        </div>
      </div>

      <div id="blocs_banner_getin_touch" className="blocs_banner_getin_touch">
        <header className="major">
          <h1 className="ourdomains_firsttitle">{data.allContentfulGetInTouch.edges[0].node.title}</h1>
          <hr />
        </header>
        <div className="row">
        {data.allContentfulGetInTouch.edges.map(({ node }) =>
                node.getintouchItems.map(({ title,description,linkButton,icon }, idx) => (
          <div className="col-md-6">
            <div className="left-side">
              <div className="text-center">
                <img src={icon.file.url} alt="" />
              </div>
              <div className="text-center">
                <h5> {title} </h5>
                
               
                <div
              dangerouslySetInnerHTML={{
                __html:
                  description.childMarkdownRemark.html
              }}
            />
                   <div
              dangerouslySetInnerHTML={{
                __html:
                linkButton.childMarkdownRemark.html
              }}
            />
              </div>
            </div>
          </div>
              ))
            )}
        </div>
      </div>

      <div id="blocs_banner_imprint" className="blocs_banner_imprint">
        <header className="major">
          <h1 className="ourdomains_firsttitle">{data.allContentfulImprint.edges[0].node.title}</h1>
          <hr />
        </header>
        <div className="row">
        <div className="col-md-12 imprint_content">
         
        <div
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulImprint.edges[0].node.description
                    .childMarkdownRemark.html
              }}
            />

        </div>
        </div>
      </div>
    </div>
  </section>
  )}
  />
);


export default Banner;
