import React from "react";


class Tab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Selected: []
        }
      
      }

      

        getInitialState(){
          return {
            selected:this.props.selected || 0
          }
        }
        render(){
         
          if( React.Children.count(this.props.children) > 1 ){
          return (<div className="row">
            <ul className="inline">
            {/* {React.Children.toArray(this.props.children).map((elem,index)=>{ */}
              {this.props.children.map((elem,index)=>{
                let style = index == this.state.selected ? 'selected': '';
                // return <li className={style} key={index} onClick={() => (elem.props.parentmethod(this.props.name),this.handleChange.bind(this,index))}>
               return <li className={style} key={index} onClick={() => {this.handleChange(index);this.globalchange();}}>
               <div className="container_image">
               <img className="card_image" src={elem.props.image} alt="" />
               </div>
               <h5 className="card_title">{elem.props.title} </h5>
               <p className="card_paragraphe">{elem.props.description} </p>
               {style === "selected" && (
             <span>Close</span>
                )}
                {style !== "selected" && (
             <span>SEE MORE</span>
                )}
               </li>
              })}
            </ul>
            <div className="tab">{this.props.children[this.state.selected]}</div>
            </div>
          )
        }
        else {

          let style = 0 == this.state.selected ? 'selected': '';
         
          return (<div className="row">
          <ul className="inline">
         
      
              {
                 
            <li className={style} key={0} onClick={() => {this.handleChange(0);this.globalchange();}}>
             <div className="container_image">
             <img className="card_image" src={this.props.children.props.image} alt="" />
             </div>
             <h5 className="card_title">{this.props.children.props.title} </h5>
             <p className="card_paragraphe">{this.props.children.props.description} </p>
             {style === "selected" && (
           <span>Close</span>
              )}
              {style !== "selected" && (
           <span>SEE MORE</span>
              )}
             </li>
              }
         
          </ul>
          {this.state.selected === 0  && <div className="tab">{this.props.children}</div>}
          {this.state.selected !== 0  && <div className="tab"></div>}
         
          </div>

          )}
        }

          globalchange(){

            this.props.onChange(this.props.name);
          }

          handleChange(index){

       
            
             console.log("selected  before:" +this.state.selected);
             console.log("indexxx :" +index);
            if (this.state.selected == index) {

              this.setState({selected:undefined});
            }
            else{
            this.setState({selected:index});
          }
             console.log("selected after :" +this.state.selected);
          }
    

}

export default Tab;
