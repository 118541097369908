import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logo from '../../assets/images/logo.png'
import { StaticQuery, graphql } from "gatsby";
const Header = (props) => (
    <StaticQuery
    query={graphql`
    query HeaderComponentQuery {
    
    
  contentfulAsset(title: { eq: "logo"}) {
    title
    file {
      url
    }
  }
          
  }
  `}
  
  render={data => (

    
    <header id="header" className="alt">
     
        <Link to="/" className="logo">
          <img src={data.contentfulAsset.file.url} alt="" />
          </Link>
        <nav>

            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;"></a>
        </nav>

    </header>
)}
/>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
