import React from 'react'
import Scrollspy from 'react-scrollspy'
import { StaticQuery, graphql } from 'gatsby' 

const Banner = (props) => (


    <StaticQuery
    query={graphql`
    query BannerComponentQuery {
    
    
        allContentfulHomeBanner {
            edges {
              node {
                title,
                subtitle {
                  subtitle
                }      
              }
            }
          }
          
  }
`}

render={data => (
  

    <section id="banner" className="major">

          {/* <button  id="myBtn" title="Go to top">Top</button> */}
          <div  id="timeline">
          <ul className="links">
            <Scrollspy items={ ['banner', 'bannermission', 'banner_what_we_do','banner_what_end_to_end','blocs_banner_our_domains','blocs_banner_getin_touch','blocs_banner_imprint'] } currentClassName="is-current">
            <li><a href="#banner" onClick={props.onToggleMenu}>Banner</a><div className="line_vertical"></div><span> 01 </span></li>
            <li><a href="#bannermission"onClick={props.onToggleMenu}>OUR MISSION</a><div className="line_vertical"></div><span> 02 </span></li>
            <li><a href="#banner_what_we_do" onClick={props.onToggleMenu}>WHAT WE DO</a><div className="line_vertical"></div><span> 03 </span></li>
            <li><a href="#banner_what_end_to_end" onClick={props.onToggleMenu}>END2END </a><div className="line_vertical"></div><span> 04 </span></li>
            <li><a href="#blocs_banner_our_domains" onClick={props.onToggleMenu}>OUR DOMAINS</a><div className="line_vertical"></div><span> 05 </span></li>
            <li><a href="#blocs_banner_getin_touch" onClick={props.onToggleMenu}>GET IN TOUCH</a><div className="line_vertical"></div><span> 06 </span></li>
            <li><a href="#blocs_banner_imprint" onClick={props.onToggleMenu}>IMPRINT</a><div className="line_vertical"></div><span> 07 </span></li>
                 </Scrollspy>
            </ul>
        </div>

        <div className="inner">
            <header className="major">
                <h1>{data.allContentfulHomeBanner.edges[0].node.title}</h1>
            </header>
            <div className="content">
                <p> {data.allContentfulHomeBanner.edges[0].node.subtitle.subtitle}</p>
            
            </div>
        </div>
    </section>
      )}
      />
 
)

export default Banner
