import React from "react";

import Tabs from "../tab/tabs";
import Panel from "../tab/panel";
import { StaticQuery, graphql } from "gatsby";

class Tb extends React.Component {



  render() {
    return (

      <StaticQuery
      query={graphql`
      query BannertbComponentQuery {
      
  
  
    
        allContentfulEnd2EndTab1  {
          edges {
            node 
            {
              title ,
              titleDescription,
              firstDescription {
                  childMarkdownRemark {
                        html
                            }
                        },
               secondDescription {
                  childMarkdownRemark {
                        html
                            }
                        },
               descriptionWithItalicText {
                  childMarkdownRemark {
                        html
                            }
                        },
        
              }
            }
          
        }



        allContentfulEnd2EndTab3  {
          edges {
            node 
            {
              title ,
              titleDescription,
              end2endTab3Items{
              title,
               contain {
                  childMarkdownRemark {
                        html
                            }
                        },
              
                }
              }
            }
          
        }
    


        allContentfulEnd2EndTab2  {
          edges {
            node 
            {
              title ,
              titleDescription,
              description{
                childMarkdownRemark {
                        html
                            }
              },
              smartCityItems,
              secondItems,
              titleCluster,
              clusterItems,
             
            
          
        
              }
            }
          
        }
        
    
            
    }
    `}

      render={data => (


      <Tabs selected={1}>
        <Panel index="1" title={data.allContentfulEnd2EndTab1.edges[0].node.title}>
          <div className="row">
            <div className="col-md-5">
              <h1>{data.allContentfulEnd2EndTab1.edges[0].node.titleDescription}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
            
            <div 
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEnd2EndTab1.edges[0].node.firstDescription
                    .childMarkdownRemark.html
              }}
            />
            </div>
            <div className="col-md-7 paragraphe_italic">
             

                <div  
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEnd2EndTab1.edges[0].node.descriptionWithItalicText
                    .childMarkdownRemark.html
              }}
            />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 paragraphefinal">
             
            <div  
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEnd2EndTab1.edges[0].node.secondDescription
                    .childMarkdownRemark.html
              }}
            />

            </div>
          </div>
        </Panel>
        <Panel index="2" title={data.allContentfulEnd2EndTab2.edges[0].node.title}>
          <div className="row">
            <div className="col-md-12">
              <h1>{data.allContentfulEnd2EndTab2.edges[0].node.titleDescription}</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div 
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEnd2EndTab2.edges[0].node.description
                    .childMarkdownRemark.html
              }}
           />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="elements borderelemnt">

                {data.allContentfulEnd2EndTab2.edges[0].node.smartCityItems.map((item)=>
                <div className="element">
                  <span> {item} </span>
                </div>
                )}
             
                <div className="smartcity_container">
                  <span className="smartcity">Smart City</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="elements">
              {data.allContentfulEnd2EndTab2.edges[0].node.secondItems.map((item) => 
              
              <div className="element">
              <span> {item} </span>
            </div>
            
              )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="graphique" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3> {data.allContentfulEnd2EndTab2.edges[0].node.titleCluster} </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ellipse_list">
              
              {
                data.allContentfulEnd2EndTab2.edges[0].node.clusterItems.map((item)=>
                
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> {item}</span>
                </div>
                )}
                {/* <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div> */}
              </div>

              {/* <div className="ellipse_list">
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
              </div> */}
            </div>
          </div>
        </Panel>
        <Panel index="3" title={data.allContentfulEnd2EndTab3.edges[0].node.title}>
          <div className="row">
            <div className="col-md-5">
              <h1>
              {data.allContentfulEnd2EndTab3.edges[0].node.titleDescription}
              </h1>
            </div>
          </div>
          <div className="row">

          {data.allContentfulEnd2EndTab3.edges.map(({node})=> node.end2endTab3Items.map(({title,contain}) => (
            <div className="col-md-6">
              <div className="left-side">
                <h3>{title}</h3>

                  
                  <div className="title_list"
              dangerouslySetInnerHTML={{
                __html:
                contain.childMarkdownRemark.html
              }}
            />
                {/* <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul> */}
              </div>
            </div>
              ))
          )}
            {/* <div className="col-md-6">
              <div className="left-side">
                <h3>Machine Learning for Streaming Data</h3>
                <ul className="title_list">
                  <li> Enrich Streaming Input Data from Sensors (in real time, no extract & load!) </li>
                  <li> Take actions based on acquired Data from Sensors on the Edge (see local inferencing before) </li>
                </ul>
              </div>
            </div> */}

             {/* <div className="col-md-6">
              <div className="left-side">
                <h3>Edge Machine Learning & AI</h3>
                <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div> */}

             {/* <div className="col-md-6">
              <div className="left-side">
                <h3>Edge Machine Learning & AI</h3>
                <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </Panel>
      </Tabs>
      )}
      />
    );
    
  }
}

export default Tb;
