import React from "react";

import microtronics from "../../assets/images/logo_micro.png";
import googlecloud from "../../assets/images/logo_google_cloud.png";
import amazonwebservices from "../../assets/images/logo_amazon.png";
import scrive from "../../assets/images/logo_scrive.png";
import azure from "../../assets/images/logo_microsoft.png";
import alibabacloud from "../../assets/images/logo_alibabacloud.png";


import Tb  from "../../components/beta/tab/tb";
import Bannermission from "./Bannermission";

import { StaticQuery, graphql } from "gatsby";


const Banner = props => (


  <StaticQuery
  query={graphql`
  query BannerendtoendComponentQuery {
  
  
    allContentfulEndToEndBanner {
      edges {
        node {
          title,
          subtitle {
            childMarkdownRemark {
                        html
                     }
          },
          leftContent{
            childMarkdownRemark {
                        html
                     }
          },
          rightContent{
           childMarkdownRemark {
                        html
                     }
          }
        }
      }
    }


  
    allContentfulAsset(
      filter:  {title: {eq: "logo partner"}}
      
    )  {
    edges{
      node {
        id
        title
          file {
      url
    }
      }
    }
  }

        
}
`}

render={data => (



  <section id="banner_what_end_to_end" className="major">
    <div className="inner">
      <div className="blocs_banner_end2end">
        <h1 className="end2end_firsttitle">{data.allContentfulEndToEndBanner.edges[0].node.title}</h1>
        <div className="row">
          <div className="col-md-6">

            <div  className="end2end_secondtitle"
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEndToEndBanner.edges[0].node.subtitle
                    .childMarkdownRemark.html
              }}
            />
            
          </div>

          
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="left-side">


                 <div 
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEndToEndBanner.edges[0].node.leftContent
                    .childMarkdownRemark.html
              }}
            />

            </div>
          </div>
          <div className="col-md-6">
            <div className="right-side">
              

                   <div 
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulEndToEndBanner.edges[0].node.rightContent
                    .childMarkdownRemark.html
              }}
            />


              {/* <ul>
                <li> Hybrid Cloud Architecture</li>
                <li>IOT & ET Brains </li>
                <li>
                  Artificial Intelligence, Robotic Process Automation, BPMN{" "}
                </li>
                <li>Covering OSS & BSS related topics (Advanced eTOM) </li>
                <li>State of the art Enterprise/Service Architectures </li>
                <li>Integration of </li>
                <li className="no-bullet">
                  <ul>
                    <li>Web </li>
                    <li>Apps </li>
                    <li>Digital Assistants (Alexa, Google Assistant) </li>
                    <li>e-Signing </li>
                  </ul>
                </li>
                <li>Reliable & Secure Infrastructure </li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="row picture_bloc">
        {data.allContentfulAsset.edges.map((item)=>
         <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center">
         <img src={item.node.file.url} alt="" />
       </div>
        )}
          
        </div>

        <div id="Tabs_bloc">

                      <Tb />

        </div>

      </div>


   


    </div>

  

 

  </section>
  )}
  />
);

export default Banner;
