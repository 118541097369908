import React from "react";

import Tab from "../tabsdomains/tab";
import Panel from "../tabsdomains/panel";

import grp_5g from "../../../assets/images/Group 5g.png";
import edge from "../../../assets/images/icn_edge.png";
import cloud from "../../../assets/images/Vector cloud.png";

import Ai from "../../../assets/images/Vector Ai.png";
import iot from "../../../assets/images/icn_iot.png";
import security from "../../../assets/images/icn_security.png";

import close_img from "../../../assets/images/btn-close.png";

import { StaticQuery, graphql } from "gatsby";

class Alltabs extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = null;
  }

  render() {
    return (
      <div>
        <Tab
          selected={1}
          name="children"
          ref="children"
          onChange={this.onChange.bind(this)}
        >
          <Panel
            index="1"
            title={
              this.props.directories.allContentfulOurDomainsTab1.edges[0].node
                .title
            }
            image={ this.props.directories.allContentfulOurDomainsTab1.edges[0].node.icon.file.url}
            description={
              this.props.directories.allContentfulOurDomainsTab1.edges[0].node
                .description
            }
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  {
                    this.props.directories.allContentfulOurDomainsTab1.edges[0]
                      .node.titlePanel
                  }
                </h1>
              </div>
            </div>

            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.directories.allContentfulOurDomainsTab1
                        .edges[0].node.descriptionPanel.childMarkdownRemark.html
                    }}
                  />
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            index="2"
            title={
              this.props.directories.allContentfulOurDomainsTab2.edges[0].node
                .title
            }
            image={this.props.directories.allContentfulOurDomainsTab2.edges[0].node.icon.file.url}
            description={
              this.props.directories.allContentfulOurDomainsTab2.edges[0].node
                .description
            }
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  {
                    this.props.directories.allContentfulOurDomainsTab2.edges[0]
                      .node.titlepanel
                  }
                </h1>
              </div>
            </div>
            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                    {
                      this.props.directories.allContentfulOurDomainsTab2
                        .edges[0].node.firstTitle
                    }
                  </h2>
                </div>
                <div className="col-md-7">
                  <div class="table-container table-responsive">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={14}>
                            Consulting (Technology Strategy, HCD, Prototyping,
                            Contract Negotiations)
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={6} rowSpan={1}>
                            Telco, IT & Data-Driven/AI Services
                          </td>
                          <td colSpan={2} rowSpan={4} className="large_td">
                            <span className="rotate">EDGE Computing </span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">End2End Security</span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">
                              Orchestration Integration
                            </span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">
                              Governance, Discovering Monitoring
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Tools & CI</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Service Architecture</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">
                              IOT Plattform Services
                            </span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">
                              Machine Learning (ML)
                            </span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Telco Services</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Identity</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">DBI</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Containers</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">IOT Integrastion</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Functions</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Pub/Sub</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Datalake</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6} rowSpan={1}>
                            Public or Private Cloud
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={8} rowSpan={1}>
                            Network Integration (SDN)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className="first_paragraphe"
                    dangerouslySetInnerHTML={{
                      __html: this.props.directories.allContentfulOurDomainsTab2
                        .edges[0].node.firstDescription.childMarkdownRemark.html
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  {
                    this.props.directories.allContentfulOurDomainsTab2.edges[0]
                      .node.secondTitle
                  }
                </h1>
              </div>
            </div>
            <div className="popup_container2">
              <div className="col-md-12">
                <h2 className="second_title_popup">
                  {
                    this.props.directories.allContentfulOurDomainsTab2.edges[0]
                      .node.secondDescription
                  }
                </h2>
              </div>

              <div className="row">
              {
                    this.props.directories.allContentfulOurDomainsTab2.edges[0]
                      .node.tab2firstitems.map(({title,description})=> 
                    
                      <div className="col-md-6">
                  <div className="right-side">
                   
                    <h3 className="third-title-popup"> {title} </h3>

                      <div className="title_list"
                    
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html
                    }}
                  />

                  </div>
                </div>
                    )
                  }
              
              </div>

              <div className="row">
              {
                    this.props.directories.allContentfulOurDomainsTab2.edges[0]
                      .node.tab2LastItems.map(({title,description})=> 
                <div className="col-md-3">
                  <h3 className="third-title-popup">
                   {title}
                  </h3>
                  <div
                    className="last_paragraphe"
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html
                    }}
                  />
                </div>
                      )}
              </div>
            </div>
          </Panel>
          <Panel
            index="3"
            title={
              this.props.directories.allContentfulOurDomainsTab3.edges[0].node
                .title
            }
            image={ this.props.directories.allContentfulOurDomainsTab3.edges[0].node.icon.file.url}
            description={
              this.props.directories.allContentfulOurDomainsTab3.edges[0].node
                .description
            }
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>

            <div className="popup_container3">
              <div className="row">
                <div className="col-md-6">
                  {/* <h3 className="third-title-popup">
                    {" "}
                    Business Benefits & Approaches{" "}
                  </h3>
                  <ul>
                    <li> Use Cases </li>
                    <li> High Scale IoT Integration </li>
                    <li> Low Latency Data Processing </li>
                    <li>IoT Hub </li>
                  </ul>

                  <h3 className="third-title-popup"> Technologies </h3>
                  <ul>
                    <li> ALIBABA IoT Brain </li>
                    <li> AWS GreenGrass </li>
                    <li> Azure IoT Edge </li>
                  </ul> */}
                      <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.directories.allContentfulOurDomainsTab3
                        .edges[0].node.leftDescription.childMarkdownRemark.html
                    }}
                  />

                </div>
                <div className="col-md-6">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.directories.allContentfulOurDomainsTab3
                        .edges[0].node.rightDescription.childMarkdownRemark.html
                    }}
                  />
                </div>
              </div>
            </div>
          </Panel>
        </Tab>

        <Tab
          selected={1}
          name="children2"
          ref="children2"
          onChange={this.onChange.bind(this)}
        >
          <Panel
            index="4"
            title={this.props.directories.allContentfulOurDomainsTab4.edges[0].node.title}
            image={this.props.directories.allContentfulOurDomainsTab4.edges[0].node.icon.file.url}
            description={this.props.directories.allContentfulOurDomainsTab4.edges[0].node.description}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                   {this.props.directories.allContentfulOurDomainsTab4.edges[0].node.titlePanel}
                </h1>
              </div>
            </div>

            <div className="popup_container2">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                  {this.props.directories.allContentfulOurDomainsTab4.edges[0].node.firstDescription}
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="third-title-popup">
                  {this.props.directories.allContentfulOurDomainsTab4.edges[0].node.secondDescription}
                  </h3>
                </div>
              </div>
              <div className="row">
              {this.props.directories.allContentfulOurDomainsTab4.edges[0].node.tab4Items.map(({title,description}) => 
              <div className="col-md-6">
              <div className="left-side">
                <h3 className="fourth-title-popup">{title} </h3>
                <div
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html
                    }}
                  />
              </div>
            </div>
              
              )}
                
                {/* <div className="col-md-6">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">Frameworks </h3>
                    <ul className="title_list">
                      <li> Tensorflow & Keras </li>
                      <li> Pytorch</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="left-side">
                    <h3 className="fourth-title-popup">
                      Link to Data Science & BI{" "}
                    </h3>
                    <ul className="title_list">
                      <li>
                        {" "}
                        Integration of Datalakes via Streams and asynchronous
                        ETL{" "}
                      </li>
                      <li> BI as starting point to ML & AI </li>
                      <li> Representation/UI Engines </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">
                      Enterprise Architecture{" "}
                    </h3>
                    <ul className="title_list">
                      <li>
                        {" "}
                        Established event driven Enterprise Architecture
                        mandatory to introduce Data Driven Business incl. AI
                        taken decisions{" "}
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </Panel>
          <Panel
            index="5"
            title= {this.props.directories.allContentfulOurDomainsTab5.edges[0].node.title}
            image={this.props.directories.allContentfulOurDomainsTab5.edges[0].node.icon.file.url}
            description={this.props.directories.allContentfulOurDomainsTab5.edges[0].node.description}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                {this.props.directories.allContentfulOurDomainsTab5.edges[0].node.titlePanel}
                </h1>
              </div>
            </div>
            <div className="popup_container2">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                  {this.props.directories.allContentfulOurDomainsTab5.edges[0].node.firstDescription}
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                <div
                      className="title_list"
                      dangerouslySetInnerHTML={{
                        __html: this.props.directories
                          .allContentfulOurDomainsTab5.edges[0].node
                          .secondDescription.childMarkdownRemark.html
                      }}
                    />
                </div>
              </div>
              <h3 className="third-title-popup">
              {this.props.directories.allContentfulOurDomainsTab5.edges[0].node.secondTitle}
              </h3>

              <ol className="steps">
              {this.props.directories.allContentfulOurDomainsTab5.edges[0].node.tab5Items.map((item) =>
              <li> {item}</li>
              )}
              </ol>
            </div>
          </Panel>
          <Panel
            index="6"
            title={this.props.directories.allContentfulOurDomainsTab6.edges[0].node.title}
            image={this.props.directories.allContentfulOurDomainsTab6.edges[0].node.icon.file.url}
            description={this.props.directories.allContentfulOurDomainsTab6.edges[0].node.description}
            parentmethod={this.globalchange}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>

            <div className="popup_container2">
              <div className="row">
                <div className="col-md-5">
                  <h3 className="third-title-popup">{this.props.directories.allContentfulOurDomainsTab6.edges[0].node.titleLeft}</h3>
                  <div
                      className="title_list"
                      dangerouslySetInnerHTML={{
                        __html: this.props.directories
                          .allContentfulOurDomainsTab6.edges[0].node
                          .descriptionLeft.childMarkdownRemark.html
                      }}
                    />
                </div>
                
                <div className="col-md-7">
                  <h3 className="fourth-title-popup">{this.props.directories.allContentfulOurDomainsTab6.edges[0].node.titleRight}</h3>
  
                  <div
                      className="firstparagraphe"
                      dangerouslySetInnerHTML={{
                        __html: this.props.directories
                          .allContentfulOurDomainsTab6.edges[0].node
                          .descriptionRight.childMarkdownRemark.html
                      }}
                    />
                </div>
                </div>

              <div className="row">
                <div className="col-md-12">
                  <h3 className="third-title-popup">{this.props.directories.allContentfulOurDomainsTab6.edges[0].node.titleItems}</h3>
                </div>
              </div>

              <div className="row">
              {this.props.directories.allContentfulOurDomainsTab6.edges[0].node.tab6Items.map(({title,description})=>
              
              <div className="col-md-4">
              <div className="left-side">
                <h3 className="fourth-title-popup">
                 {title}
                </h3>
                <div
                      className="firstparagraphe"
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html
                      }}
                    />
              </div>
            </div>
            
            )}

               
                {/* <div className="col-md-4">
                  <div className="center-side">
                    <h3 className="fourth-title-popup">
                      Programming Languages & Frameworks
                    </h3>
                    <ul>
                      <li> Go (Kit, GORM, …) </li>
                      <li> Python (Flask & especially link to AI!) </li>
                      <li> Node.JS (Loopback) </li>
                      <li> Java (Spring) </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">
                      Strong focus on Governance & Practices
                    </h3>
                    <ul>
                      <li>
                        Identity Access Management (OpenID & JWT incl. ABAC,
                        RBAC or ACL){" "}
                      </li>
                      <li>
                        End 2 End Tracing&Logging, Performance Management &
                        Service Discovery{" "}
                      </li>
                      <li> Data Reference Management (i.e. to cover GDPR) </li>
                      <li>
                        {" "}
                        Integration Pipelines incl. Blue & Red Deployments{" "}
                      </li>
                      <li> API/RPC Versioning & deprecation Policies </li>
                    </ul>
                  </div>
                </div> */}
             </div>
            </div>
          </Panel>
        </Tab>

        <Tab
          selected={1}
          name="children3"
          ref="children3"
          onChange={this.onChange.bind(this)}
        >
          <Panel
            index="7"
            title={this.props.directories.allContentfulOurDomainsTab7.edges[0].node.title}
            image={this.props.directories.allContentfulOurDomainsTab7.edges[0].node.icon.file.url}
            description= {this.props.directories.allContentfulOurDomainsTab7.edges[0].node.description}
            parentmethod={this.globalchange}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children3.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                 {this.props.directories.allContentfulOurDomainsTab7.edges[0].node.titlePanel}
                </h1>
              </div>
            </div>

            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.directories.allContentfulOurDomainsTab7
                        .edges[0].node.descriptionPanel.childMarkdownRemark.html
                    }}
                  />
                </div>
              </div>
            </div>
          </Panel>
          {/* <Panel
            index="7"
            title="Classic IT"
            image={security}
            description="We are actively assisting you to capture in an early stage 5G business opportunities."
          >
          </Panel> */}
        </Tab>
      </div>
    );
  }

  onChange(field) {
    if (field === "children3") {
      this.refs.children2.handleChange();
      this.refs.children.handleChange();
    } else if (field === "children2") {
      this.refs.children3.handleChange();
      this.refs.children.handleChange();
    } else {
      this.refs.children3.handleChange();
      this.refs.children2.handleChange();
    }
  }
}

// export default Alltabs;

export default () => (
  <StaticQuery
    query={graphql`
      query alltabsComponentQuery {
        allContentfulOurDomainsTab1 {
          edges {
            node {
              icon{
                file {
                    url
                      }
            },
              title
              description
              titlePanel
              descriptionPanel {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }

         allContentfulOurDomainsTab2  {
      edges {
    	node
        {
          icon{
            file{
              url
            }
          },
            title,
          	description,
            titlepanel,
         		firstTitle,
            firstDescription{
            	childMarkdownRemark{
              html
            }
            },
          secondTitle,
          secondDescription,
            tab2firstitems{
            title,
            description{
            childMarkdownRemark {
                    html
                        }
          },
              
            },
          
            tab2LastItems{
            title,
            description{
            childMarkdownRemark {
                    html
                        }
          },
              
            }
         
            
       }    
      }
        }

          allContentfulOurDomainsTab3  {
            edges {
            node
              {
                  title,
                  description,
                  leftDescription{
                    childMarkdownRemark{
                    html
                  }
                  },
                  rightDescription{
                    childMarkdownRemark{
                    html
                  }
                  
             }
             ,
                        icon{
   												 file {
                                url
                                  }
                                }    
            }
              }
          }

          allContentfulOurDomainsTab4  {
            edges {
            node
              {
                icon{
                  file {
                      url
                        }
              },
                  title,
                  description,
                  titlePanel,
                  firstDescription,
                  secondDescription,
                 tab4Items{
                  title,
                  description{
                  childMarkdownRemark {
                          html
                              }
                },
                }
                  
             }    
            }
              }


          allContentfulOurDomainsTab5  {
            edges {
            node
              {
                icon{
                  file {
                      url
                        }
              },
                  title,
                  description,
                  titlePanel,
                  firstDescription,
                  secondDescription{
                    childMarkdownRemark{
                    html
                  }
                  },
                 secondTitle,
                tab5Items
                  
             }    
            }
              }

              allContentfulOurDomainsTab6  {
                edges {
                node
                  {
                    icon{
                      file {
                          url
                            }
                  },
                      title,
                      description,
                      titleLeft,
                      descriptionLeft{
                      childMarkdownRemark {
                              html
                                  }
                    },
                      titleRight,
                      descriptionRight{
                      childMarkdownRemark {
                              html
                                  }
                    },
                      titleItems,
                     tab6Items{
                      title,
                      description{
                      childMarkdownRemark {
                              html
                                  }
                    },
                    }
                      
                 }    
                }
                  }

              allContentfulOurDomainsTab7  {
                edges {
                node
                  {    icon{
                    file {
                        url
                          }
                },
                      title,
                      description,
                      titlePanel,
                      descriptionPanel{
                        childMarkdownRemark{
                        html
                      }
                    }
                      
                 }    
                }
                  }
              
      }
    `}
    render={data => <Alltabs directories={data} />}
  />
);
