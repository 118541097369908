import React from "react";
import { StaticQuery, graphql } from "gatsby";

const Banner = props => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        allContentfulOurMission {
          edges {
            node {
              title
              description_html {
                childMarkdownRemark {
                  html
                }
              }
              ourmissiontabs {
                item_description {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section id="bannermission" className="major">
        <div className="inner">
          <header className="major">
            <h1>
              {data.allContentfulOurMission.edges[0].node.title}
            </h1>
            <hr />
          </header>
          <div className="content">
       
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulOurMission.edges[0].node.description_html
                    .childMarkdownRemark.html
              }}
            />
       
          </div>
          <div className="blocs_banner">
            <div className="row">
              {data.allContentfulOurMission.edges.map(({ node }) =>
                node.ourmissiontabs.map(({ item_description }, idx) => (
                  <div className="col-md-6 bloc_messions">
                    <div className="blocnumber">
                      <div className="number">{idx + 1}</div>
                    </div>
                    <div className="text_bloc_mission">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item_description.childMarkdownRemark.html
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    )}
  />
);

export default Banner;
